@import 'includes';

/* https://markdotto.com/2018/02/07/github-system-fonts/ */
$fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$fonts-mono: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$link-color: #0284c7;
$link-color-hover: #38bdf8;

body {
  font-family: $fonts;
  color: #111827;
  font-size: 16px;
  line-height: 1.6;
}

p {
  margin: 1.25em 0;
}

em, i {
  font-style: italic;
}

strong, b {
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  margin: 1.5em 0 1em 0;
  line-height: 1em;
}

h1 {
  font-size: 1.625em;
}
h2 {
  font-size: 1.375em;
}
h3 {
  font-size: 1.125em;
}
h4 {
  font-size: 1em;
}
h5 {
  font-size: 0.875em;
}
h6 {
  font-size: 0.75em;
}

h4, h5, h6 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 2.5em;
}

a {
  color: $link-color;
  transition: color 0.1s ease;

  &:hover {
    color: $link-color-hover;
  }
}

ol li {
  list-style: decimal;
  margin-left: 1.25em;
  margin-bottom: 0.2em;
}

ul li {
  list-style: disc;
  margin-left: 1.25em;
  margin-bottom: 0.2em;
}

dt {
  font-weight: bold;
}
dd {
  margin-left: 1.25em;
}

hr {
  margin: 2em 0;
  border: 0;
  border-top: 2px dotted #aaa;
}

pre, code {
  font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, monospace;
  border-radius: 3px;
}
code {
  background: #f9f9f9;
  font-size: 0.9em;
  padding: 0.25em 0.5em;
  color: #9E525A;
}
pre code {
  background: #f9f9f9;
  display: block;
  margin: 1em 0;
  padding: 0.75em 1em;
  color: inherit;
}

img, video {
  max-width: 100%;
  height: auto;
  border-radius: 3px;
}
figure {
  width: 100%;
  margin: 2em 0;
  text-align: center;
}
figcaption {
  p {
    margin: 0;
  }
  text-align: center;
  font-size: 0.9em;
  color: #666;
}

blockquote {
  padding-left: 1.25em;
  padding-right: 1.5em;
  border-left: .25em solid #eee;
  color: #666;
}

sup, sub {
  font-size: 0.8em;
}
sup {
  vertical-align: super;
}

main {
  max-width: 740px;
  padding: 0 20px;
  margin: 0 auto;
}

header h2 {
  margin: 1em;
  a {
    text-decoration: none;
    border: none;
  }
}

.combined-title-pagination {
  margin-top: 2.5em;
  h1 {
    display: inline;
    a {
      text-decoration: none;
      border: none;
    }
  }
  h3 {
    display: inline;
    margin-left: 0.2em;
  }
}

.title-group {
  .title h1 {
    margin-bottom: 0.5em;
  }
  .date h5 {
    color: #888;
    margin: 0;
  }
  margin-bottom: 1.5em;
}

footer {
  margin-top: 3em;
  .content-container {
    background: #f3f4f6;
    padding: 2em 0;
    .content {
      max-width: 740px;
      padding: 0 20px;
      margin: 0 auto;
    }
  }
}

.horizontal-links {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0.25em;
  a {
    border-right: 2px solid #ccc;
    padding-right: 0.5em;
    margin-right: 0.5em;
    text-decoration: none;
    font-size: small;
  }
  a:last-child {
    border: none;
  }
  &:last-child {
    margin-bottom: 1.25em;
  }
}

.post-list {
  table, tr, td {
    border: 0;
    background: none;
    vertical-align: middle;
  }

  .title a {
    text-decoration: none;
    font-weight: bold;
    border: none;
  }

  .date {
    font-size: x-small;
    font-family: $fonts-mono;
    text-align: right;
    color: #4b5563;
    padding: 0 10px;
  }

  padding-bottom: 0.25em;
}

.tags {
  font-size: 0.9em;
  .links {
    display: inline;
    margin-left: 0.5em;
    a {
      color: #aaa;
      text-decoration: none;
      border-bottom: none;
      &:hover {
        color: #888;
        text-decoration: underline;
      }
    }
  }
}

.paginator {
  margin: 2em 0;
  .disabled {
    color: #aaa;
  }
  .newer {
    float: right;
    clear: both;
  }
}

.current-page {
  color: #aaa;
}

@media (max-width:400px) {
  body {
    font-size: 14px;
  }
}

.disclaimer {
  margin-top: 1em;
  font-size: 0.7em;
  color: #666;
}
